import "./style"
import {Image, Modal} from "react-native"
import CloseIcon from "./close-icon.png"
import hasParent from "shared/has-parent"

export default memo(shapeComponent(class ComponentsModal extends ShapeComponent {
  static defaultProps = {
    active: true
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    footer: PropTypes.node,
    onRequestClose: PropTypes.func.isRequired
  }

  render() {
    const props = this.props
    const s = useShape(props)
    const {active, children, className, footer, onRequestClose, ...restProps} = props

    s.meta.cardRef = useRef()

    const onCloseModalClicked = useCallback((e) => {
      e.preventDefault()
      s.p.onRequestClose()
    }, [])

    const onWindowKeyUp = useCallback((e) => {
      if (e.key == "Escape" || e.key == "Esc" || e.keyCode == 27) { // Escape key
        e.preventDefault()
        s.p.onRequestClose()
      }
    }, [])

    const onWindowMouseUp = useCallback((e) => {
      // Ignore dismissing notification message - it shouldn't also close the modal
      if (hasParent(e.target, (element) => element.dataset?.class?.includes("flash-notifications-notification"))) return

      if (s.m.cardRef.current && !s.m.cardRef.current.contains(e.target)) s.p.onRequestClose()
    }, [])

    if (!active) return null

    useEventListener(window, "keyup", onWindowKeyUp)
    useEventListener(window, "mouseup", onWindowMouseUp)

    return (
      <Modal onRequestClose={onRequestClose} transparent>
        <View
          dataSet={{"class": className, "components--modal": true}}
          style={{
            position: "fixed",
            zIndex: 9999,
            width: "100vw",
            height: "100vh",
            background: "rgba(0 0 0 / 70%)"
          }}
          {...restProps}
        >
          <Card cardRef={s.m.cardRef} className="modal-card" footer={footer} scrollable>
            {onRequestClose &&
              <View style={{position: "relative"}}>
                <Pressable
                  dataSet={{class: "close-modal-button"}}
                  onPress={onCloseModalClicked}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "#000"
                  }}
                >
                  <Image source={CloseIcon} style={{width: 20, height: 20}} />
                </Pressable>
              </View>
            }
            {children}
          </Card>
        </View>
      </Modal>
    )
  }
}))
