import i18nEvents from "i18n-on-steroids/src/events"
import {shouldComponentUpdate} from "set-state-compare"

export default class BaseComponent extends React.Component {
  originalComponentDidMount = this.componentDidMount
  originalRender = this.render
  p = fetchingObject(() => this.props)
  s = fetchingObject(() => this.shape || this.state)
  t = fetchingObject(this)

  render = () =>
    <>
      <EventEmitterListener event="localeChanged" events={i18nEvents} onCalled={this.onLocaleChanged} />
      {this.originalRender()}
    </>

  componentDidMount = () => {
    if (!this.shape) this.shape = new Shape(this, {})

    this.shape.currentLocale = I18n.locale

    if (this.originalComponentDidMount) this.originalComponentDidMount()
  }

  onLocaleChanged = () => {
    this.shape.set({
      currentLocale: I18n.locale
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState)
  }
}
