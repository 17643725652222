const hasParentClass = (element, callback) => {
  let currentElement = element

  while (currentElement) {
    const result = callback(currentElement) // eslint-disable-line callback-return

    if (result) {
      return result
    }

    currentElement = currentElement.parentNode
  }

  return false
}

export default hasParentClass
