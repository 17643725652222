import "./style"

export default class ComponentsDynamicOverflow extends BaseComponent {
  absoluteContainerRef = React.createRef()
  dynamicOverflowRef = React.createRef()

  componentDidMount() {
    this.changeAbsoluteContainerHeight()

    this.resizeObserver = new ResizeObserver(this.t.changeAbsoluteContainerHeight)
    this.resizeObserver.observe(this.t.dynamicOverflowRef.current)
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect()
  }

  render() {
    const {absoluteContainerRef, dynamicOverflowRef} = this.t
    const {children, className, ...restProps} = this.props

    return (
      <div className="components--dynamic-overflow" ref={dynamicOverflowRef}>
        <div className={classNames("dynamic-overflow-absolute-container", className)} ref={absoluteContainerRef} {...restProps}>
          {children}
        </div>
      </div>
    )
  }

  changeAbsoluteContainerHeight = () => {
    const height = this.t.dynamicOverflowRef.current.offsetHeight

    this.t.absoluteContainerRef.current.style.height = `${height}px`
  }
}
